import React from 'react';

interface HamburgerMenuProps {
  onToggle: () => void;
}

const HamburgerMenu: React.FC<HamburgerMenuProps> = ({ onToggle }) => {
  return (
    <button
      onClick={onToggle}
      className="absolute top-4 right-4 bg-white rounded-md shadow-md p-2 z-50 hover:bg-gray-200 transition"
    >
      <span className="block w-6 h-0.5 bg-gray-800 mb-1"></span>
      <span className="block w-6 h-0.5 bg-gray-800 mb-1"></span>
      <span className="block w-6 h-0.5 bg-gray-800"></span>
    </button>
  );
};

export default HamburgerMenu;
