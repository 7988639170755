import * as THREE from 'three';
import Model from './Model';
import Terrain from './Terrain';

class ModelManager {
  isRotating: boolean = false;
  models: Model[] = [];
  scene: THREE.Scene;

  log: (message: string) => void;

  constructor(scene: THREE.Scene, log: (message: string) => void) {
    this.scene = scene;
    this.log = log;
  }

  addModel(model: Model) {
    this.models.push(model);
    this.scene.add(model.mesh); // Add the model's mesh to the scene
  }

  removeModel(model: Model) {
    const index = this.models.indexOf(model);
    if (index > -1) {
      this.models.splice(index, 1); // Remove from the array
      this.scene.remove(model.mesh); // Remove the model's mesh from the scene
    }
  }

  updateModels(terrains: Terrain[]) {
    this.models.forEach(model => {
      // Skip gravity and collision updates if the model is currently being moved manually
      if (model.isMoving) {
        if (model.isLifting && !model.hasLifted) {
          const liftAmount = 4; // Amount to lift (e.g., 4 inches)
          model.mesh.position.y += liftAmount;
          model.liftHeight += liftAmount; // Store the lift height
          model.isOnGround = false; // Set to not on ground since it is lifted
          model.velocity = 0; // Reset gravity velocity after lifting
          model.hasLifted = true; // Mark as lifted to prevent continuous lifting
        }
        return; // Do not apply gravity or collision checks while the model is being actively moved
      }

      // Apply gravity to the model
      if (!model.isOnGround) {
        model.velocity += model.gravity; // Apply gravity force
        model.mesh.position.y += model.velocity; // Update vertical position

        // Check if the model collides with any terrain
        let terrainCollision = false;
        let highestCollisionPoint = -Infinity;

        terrains.forEach(terrain => {
          const terrainBoundingBox = new THREE.Box3().setFromObject(terrain.mesh);
          const modelBoundingBox = new THREE.Box3().setFromObject(model.mesh);

          if (modelBoundingBox.intersectsBox(terrainBoundingBox)) {
            terrainCollision = true;

            // Calculate the highest point of collision on the terrain
            const terrainHeight = terrainBoundingBox.max.y;
            if (terrainHeight > highestCollisionPoint) {
              highestCollisionPoint = terrainHeight;
            }
          }
        });

        if (terrainCollision) {
          // Place the model on top of the terrain at the highest collision point
          model.mesh.position.y = highestCollisionPoint + 0.5; // Add offset to avoid clipping
          model.isOnGround = true; // Set on-ground state
          model.velocity = 0; // Reset velocity
          model.hasLifted = false; // Allow lifting again after landing
          model.liftHeight = 0; // Reset lift height
        }

        // Check if the model is below ground level (assuming ground level is 0.5)
        if (model.mesh.position.y <= 0.5 && !terrainCollision) {
          model.mesh.position.y = 0.5; // Snap to ground level
          model.isOnGround = true; // Set on-ground state
          model.velocity = 0; // Reset velocity
          model.hasLifted = false; // Allow lifting again after landing
          model.liftHeight = 0; // Reset lift height
        }
      }

      // Update the bounding box of the model after movement
      model.updateBoundingBox();
    });
  }

  handleMouseDown(event: MouseEvent, camera: THREE.Camera) {
    const mouse = new THREE.Vector2();
    mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

    const raycaster = new THREE.Raycaster();
    raycaster.setFromCamera(mouse, camera); // Use the passed camera

    const intersects = raycaster.intersectObjects(this.models.map(model => model.mesh));

    if (intersects.length > 0) {
      const selectedModel = this.models.find(model => model.mesh === intersects[0].object);
      if (selectedModel) {
        if (this.isRotating) {
          selectedModel.handleRotationStart(this.scene); // Start rotation
        } else {
          selectedModel.startMove(); // Start moving the selected model
          selectedModel.drawCircle(this.scene); // Draw the circle for the selected model
        }
      }
    }
  }

  handleMouseMove(event: MouseEvent, camera: THREE.Camera, ground: THREE.Mesh, terrains: Terrain[]) {
    const mouse = new THREE.Vector2();
    mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

    const raycaster = new THREE.Raycaster();
    raycaster.setFromCamera(mouse, camera);

    const planeIntersect = raycaster.intersectObject(ground);

    if (planeIntersect.length > 0) {
      const point = planeIntersect[0].point;

      this.models.forEach(model => {
        if (this.isRotating && model.isRotating) {
          const mouseAngle = this.calculateMouseAngle(model, point);
          this.log(`Mouse Angle: ${mouseAngle}`);
          model.handleRotationUpdate(this.scene, mouseAngle);
        } else if (!this.isRotating && model.isMoving) {
          // Calculate the velocity (dx, dy, dz)
          const dx = point.x - model.mesh.position.x;
          const dy = point.y - model.mesh.position.y;
          const dz = point.z - model.mesh.position.z;

          // Create velocity vector
          let velocity = new THREE.Vector3(dx, dy, dz);

          // First check for collisions with terrain
          velocity = this.preCheckCollisions(model, terrains, velocity);

          // Now check for collisions with other models
          velocity = this.precheckModelCollisions(model, this.models, velocity);

          // Move the model with adjusted velocity
          model.move(velocity.x, velocity.y, velocity.z, this.scene);

          this.log(`Model Position: x: ${velocity.x}, y: ${velocity.y}, z: ${velocity.z}`);
        }
      });
    }
  }

  handleMouseUp() {
    this.models.forEach(model => {
      if (this.isRotating && model.isRotating) {
        const finalAngle = model.mesh.rotation.y; // Capture the final angle
        model.handleRotationEnd(this.scene, finalAngle); // Finalize rotation
      } else if (!this.isRotating && model.isMoving) {
        model.removeMovementWidgets(this.scene);
        model.stopMoving();
      }
    });
  }

  handleKeyDown(event: KeyboardEvent) {
    if (event.key === 'r') {
      this.isRotating = true;
    } else if (event.key === 'u') {
      this.log('uKey down')
      this.models.forEach(model => {
        if (model.isMoving) {
          this.log('model moving')
          model.isLifting = true; // Set lifting state
        }
      });
    }
  }

  handleKeyUp(event: KeyboardEvent) {
    if (event.key === 'r') {
      this.isRotating = false;
    } else if (event.key === 'u') {
      this.log('uKey up')
      this.models.forEach(model => {
        if (model.isMoving) {
          model.isLifting = false; // Reset lifting state
        }
      });
    }
  }

  // Helper function to calculate the mouse angle relative to the model
  calculateMouseAngle(model: Model, point: THREE.Vector3): number {
    const dx = point.x - model.mesh.position.x;
    const dz = point.z - model.mesh.position.z;
    return Math.atan2(dz, dx);
  }

  preCheckCollisions(model: Model, terrains: Terrain[], velocity: THREE.Vector3): THREE.Vector3 {
    let adjustedVelocity = velocity.clone();  // Start with original velocity

    // Handle movement on the X-axis first
    terrains.forEach(terrain => {
      const modelBoundingBox = new THREE.Box3().setFromObject(model.mesh);
      const nextBoundingBoxX = new THREE.Box3().copy(modelBoundingBox).translate(new THREE.Vector3(adjustedVelocity.x, 0, 0));
      const terrainBoundingBox = new THREE.Box3().setFromObject(terrain.mesh);

      if (nextBoundingBoxX.intersectsBox(terrainBoundingBox)) {
        // Collision on X-axis, stop movement on X
        adjustedVelocity.x = 0;
      }
    });

    // Handle movement on the Z-axis next, only if there is no collision on the X-axis or it's allowed
    terrains.forEach(terrain => {
      const modelBoundingBox = new THREE.Box3().setFromObject(model.mesh);
      const nextBoundingBoxZ = new THREE.Box3().copy(modelBoundingBox).translate(new THREE.Vector3(0, 0, adjustedVelocity.z));
      const terrainBoundingBox = new THREE.Box3().setFromObject(terrain.mesh);

      if (nextBoundingBoxZ.intersectsBox(terrainBoundingBox)) {
        // Collision on Z-axis, stop movement on Z
        adjustedVelocity.z = 0;
      }
    });

    terrains.forEach(terrain => {
      const modelBoundingBox = new THREE.Box3().setFromObject(model.mesh);
      const nextBoundingBoxY = new THREE.Box3().copy(modelBoundingBox).translate(new THREE.Vector3(0, adjustedVelocity.y, 0));
      const terrainBoundingBox = new THREE.Box3().setFromObject(terrain.mesh);

      if (nextBoundingBoxY.intersectsBox(terrainBoundingBox)) {
        this.log('Gonna stop moving on y')
        adjustedVelocity.y = 0;
      }
    })

    return adjustedVelocity;  // Return adjusted velocity after checking both axes
  }

  precheckModelCollisions(currentModel: Model, models: Model[], velocity: THREE.Vector3) {
    // Create a clone of the current model's bounding box
    const currentBoundingBox = new THREE.Box3().setFromObject(currentModel.mesh);

    // Create a clone of the future position to check for collisions
    const futureBoundingBox = currentBoundingBox.clone().translate(velocity);

    models.forEach(otherModel => {
      if (currentModel === otherModel) return; // Skip checking collision with itself

      const otherBoundingBox = new THREE.Box3().setFromObject(otherModel.mesh);

      if (futureBoundingBox.intersectsBox(otherBoundingBox)) {
        // Collision detected along one or more axes, restrict velocity

        // Check each axis individually and zero out velocity for collided axes
        if (this.isAxisColliding(currentBoundingBox, otherBoundingBox, 'x')) {
          velocity.x = 0; // Stop movement along X
        }
        if (this.isAxisColliding(currentBoundingBox, otherBoundingBox, 'y')) {
          velocity.y = 0; // Stop movement along Y
        }
        if (this.isAxisColliding(currentBoundingBox, otherBoundingBox, 'z')) {
          velocity.z = 0; // Stop movement along Z
        }
      }
    });

    return velocity;
  }

  isAxisColliding(bbox1: THREE.Box3, bbox2: THREE.Box3, axis: string) {
    if (axis === 'x') {
      return (bbox1.max.x > bbox2.min.x && bbox1.min.x < bbox2.max.x);
    } else if (axis === 'y') {
      return (bbox1.max.y > bbox2.min.y && bbox1.min.y < bbox2.max.y);
    } else if (axis === 'z') {
      return (bbox1.max.z > bbox2.min.z && bbox1.min.z < bbox2.max.z);
    }
    return false;
  }
}

export default ModelManager;
