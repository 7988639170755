import * as THREE from 'three';

class Terrain {
  isDragging: boolean = false;
  isYAdjusting: boolean = false;
  initialY: number = 0;
  boundingBox: THREE.Box3;
  mesh: THREE.Mesh;

  constructor(geometry: THREE.BufferGeometry, material: THREE.Material) {
    this.mesh = new THREE.Mesh(geometry, material);
    this.boundingBox = new THREE.Box3().setFromObject(this.mesh);
  }

  updateBoundingBox() {
    this.mesh.updateMatrixWorld(true); // Ensure the world matrix is up-to-date
    this.boundingBox.setFromObject(this.mesh);
  }

  startDragging() {
    this.isDragging = true;
  }

  stopDragging() {
    this.isDragging = false;
  }

  adjustYPosition(movementY: number) {
    if (this.isYAdjusting) {
      this.mesh.position.y = this.initialY + (movementY * 0.1); // Adjust sensitivity as needed
    }
  }

  startYAdjusting() {
    this.isYAdjusting = true;
    this.initialY = this.mesh.position.y; // Store the initial y position
  }

  stopYAdjusting() {
    this.isYAdjusting = false;
  }

  // Additional methods to interact with the mesh
  setPosition(x: number, y: number, z: number) {
    this.mesh.position.set(x, y, z);
    this.updateBoundingBox();
  }

  getPosition() {
    return this.mesh.position.clone();
  }
}

export default Terrain;
