// Sidebar.tsx
import React from 'react';

interface SidebarProps {
  isOpen: boolean;
  onClose: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({ isOpen, onClose }) => {
  return (
    <div
      className={`fixed top-0 right-0 h-full w-64 bg-white shadow-lg transition-transform transform z-50 ${
        isOpen ? 'translate-x-0' : 'translate-x-full'
      }`}
      style={{ transition: 'transform 0.3s ease' }}
    >
      <button
        onClick={onClose}
        className="m-4 p-2 bg-red-500 text-white rounded-md hover:bg-red-600 transition"
      >
        Close
      </button>
      <div className="p-4 text-black">
        <h2 className="text-xl font-bold">Sidebar Menu</h2>
        <ul className="mt-4">
          <li className="py-2 hover:bg-gray-100 transition">Menu Item 1</li>
          <li className="py-2 hover:bg-gray-100 transition">Menu Item 2</li>
          <li className="py-2 hover:bg-gray-100 transition">Menu Item 3</li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
