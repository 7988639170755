import * as THREE from 'three';

class Model {
  moveDistance: number;
  geometry: THREE.BoxGeometry;
  materials: THREE.Material[];
  mesh: THREE.Mesh;
  circle: THREE.Line | null = null;
  line: THREE.Line | null = null;
  rotateCircle: THREE.Line | null = null;
  lineCurrent: THREE.Line | null = null; // Yellow line for current direction
  lineTarget: THREE.Line | null = null; // Green line for new direction
  originalPosition: THREE.Vector3 | null = null;
  distanceLabel: THREE.Sprite | null = null; // Add a property for the distance label
  isMoving: boolean = false;
  isLifting: boolean = false;
  hasLifted: boolean = false;
  liftHeight: number = 0;
  boundingBox: THREE.Box3;

  // Gravity variables
  gravity: number = -0.1; // Gravity force
  velocity: number = 0; // Current vertical velocity
  isOnGround: boolean = true; // Check if the model is on the ground

  // Rotation variables
  isRotating: boolean = false;
  startAngle: number = 0;
  currentAngle: number = 0;

  // Collision variables
  // lock movement along specific axes
  isMovementLockedX: boolean = false;
  isMovementLockedZ: boolean = false;

  constructor(scene: THREE.Scene, moveDistance: number = 5, initialPosition: THREE.Vector3 = new THREE.Vector3(0, 0, 0)) {
    this.moveDistance = moveDistance; // Maximum movement in inches

    // Define materials for each face of the cube
    this.materials = [
      new THREE.MeshBasicMaterial({ color: 0x0000ff }), // Front face
      new THREE.MeshBasicMaterial({ color: 0x00ff00 }), // Back face
      new THREE.MeshBasicMaterial({ color: 0xff0000 }), // Top face (XY plane)
      new THREE.MeshBasicMaterial({ color: 0x00ff00 }), // Bottom face
      new THREE.MeshBasicMaterial({ color: 0x00ff00 }), // Right face
      new THREE.MeshBasicMaterial({ color: 0x00ff00 }), // Left face
    ];

    this.geometry = new THREE.BoxGeometry(1, 1, 1); // Simple cube for the model
    this.mesh = new THREE.Mesh(this.geometry, this.materials);
    this.mesh.position.copy(initialPosition); // Set the initial position
    this.boundingBox = new THREE.Box3().setFromObject(this.mesh).expandByScalar(1);
    scene.add(this.mesh);

    this.createDistanceLabel(scene);
  }

  drawCircle(scene: THREE.Scene) {
    const segments = 64;
    const circleGeometry = new THREE.BufferGeometry();
    const circleVertices = [];

    // Create circle vertices in the XZ plane
    for (let i = 0; i <= segments; i++) {
      const theta = (i / segments) * Math.PI * 2;
      const x = this.moveDistance * Math.cos(theta);
      const z = this.moveDistance * Math.sin(theta);
      circleVertices.push(x, 0.01, z); // Y is set slightly above 0 to avoid clipping with the ground
    }

    circleGeometry.setAttribute('position', new THREE.Float32BufferAttribute(circleVertices, 3));
    const circleMaterial = new THREE.LineBasicMaterial({ color: 0xff0000 });
    this.circle = new THREE.LineLoop(circleGeometry, circleMaterial);

    // Set the circle's position to match the model's position on the ground
    this.circle.position.set(this.mesh.position.x, 0.01, this.mesh.position.z); // Set slightly above ground to prevent clipping
    scene.add(this.circle);
  }

  removeCircle(scene: THREE.Scene) {
    if (this.circle) {
      scene.remove(this.circle);
      this.circle = null;
    }
  }

  startMove() {
    this.isMoving = true;
    this.originalPosition = this.mesh.position.clone();
  }

  drawLine(scene: THREE.Scene, currentPosition: THREE.Vector3) {
    if (!this.originalPosition) return;

    const points = [this.originalPosition, currentPosition];
    const lineGeometry = new THREE.BufferGeometry().setFromPoints(points);
    const lineMaterial = new THREE.LineBasicMaterial({ color: 0xffffff });
    if (this.line) {
      scene.remove(this.line);
    }
    this.line = new THREE.Line(lineGeometry, lineMaterial);
    scene.add(this.line);
  }

  removeLine(scene: THREE.Scene) {
    if (this.line) {
      scene.remove(this.line);
      this.line = null;
    }
  }

  removeMovementWidgets(scene: THREE.Scene) {
    this.removeCircle(scene);
    this.removeLine(scene);
  }

  createDistanceLabel(scene: THREE.Scene) {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (context) {
      context.font = '24px Arial'; // Set font size and family
      context.fillStyle = 'white'; // Set text color
      context.fillText('Distance: 0.00', 0, 24); // Initial text
    }

    const texture = new THREE.Texture(canvas);
    texture.needsUpdate = true; // Mark the texture as needing an update

    const spriteMaterial = new THREE.SpriteMaterial({ map: texture });
    this.distanceLabel = new THREE.Sprite(spriteMaterial);
    this.distanceLabel.scale.set(2, 1, 2); // Adjust the scale as needed
    this.distanceLabel.visible = false; // Initially hide the label
    scene.add(this.distanceLabel);
  }

  updateDistanceLabel(distance: number) {
    if (this.distanceLabel) {
      // Update the canvas with the new distance
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      if (context) {
        context.font = '24px Arial'; // Set font size and family
        context.fillStyle = 'white'; // Set text color
        context.clearRect(0, 0, canvas.width, canvas.height); // Clear the canvas
        context.fillText(`Distance: ${distance.toFixed(2)}`, 0, 24); // Update text
      }

      const texture = new THREE.Texture(canvas);
      texture.needsUpdate = true; // Mark the texture as needing an update
      this.distanceLabel.material.map = texture; // Update the sprite's texture

      // Position the label next to the model
      this.distanceLabel.position.copy(this.mesh.position);
      this.distanceLabel.position.y += 1; // Adjust the height if needed
      this.distanceLabel.visible = true; // Show the label while moving
    }
  }

  // Update the bounding box based on the current position
  updateBoundingBox() {
    this.boundingBox.setFromObject(this.mesh);
  }

  // Check for collision with another model
  checkCollision(otherModel: Model): boolean {
    return this.boundingBox.intersectsBox(otherModel.boundingBox);
  }

  move(dx: number, dy: number, dz: number, scene: THREE.Scene) {
    if (!this.originalPosition) return;

    // Calculate the new position based on dx, dy, and dz
    const newPosition = new THREE.Vector3(
      this.mesh.position.x + dx,
      this.liftHeight > 0 ? this.liftHeight : this.mesh.position.y + dy, // Maintain lifted height if lifted
      this.mesh.position.z + dz
    );

    // Calculate the distance moved from the original position
    const distanceFromOriginal = newPosition.distanceTo(this.originalPosition);

    // Check if the distance moved is within the allowed limit
    if (distanceFromOriginal <= this.moveDistance) {
      // Prevent clipping through the ground
      if (newPosition.y < 0.5 && this.liftHeight === 0) {
        newPosition.y = 0.5; // Set to ground level only if not lifted
      }
      this.mesh.position.copy(newPosition);
      this.drawLine(scene, newPosition);
      this.updateDistanceLabel(distanceFromOriginal); // Update the distance label
    }
  }

  stopMoving() {
    this.isMoving = false; // Set moving state to false
    if (this.distanceLabel) {
      this.distanceLabel.visible = false; // Hide the label when not moving
    }
  }

  // Method to draw the rotation circle with two lines (yellow for current direction, green for target)
  drawRotationCircle(scene: THREE.Scene) {
    const segments = 64;
    const circleGeometry = new THREE.BufferGeometry();
    const circleVertices = [];

    // Create vertices for the circle
    for (let i = 0; i <= segments; i++) {
      const theta = (i / segments) * Math.PI * 2;
      const x = this.moveDistance * Math.cos(theta);
      const z = this.moveDistance * Math.sin(theta);
      circleVertices.push(x, 0, z); // Draw circle on XZ plane
    }

    circleGeometry.setAttribute('position', new THREE.Float32BufferAttribute(circleVertices, 3));
    const circleMaterial = new THREE.LineBasicMaterial({ color: 0xff0000 });
    this.circle = new THREE.LineLoop(circleGeometry, circleMaterial);
    this.circle.position.set(this.mesh.position.x, 0, this.mesh.position.z);
    scene.add(this.circle);

    // Add yellow (current direction) and green (target direction) lines
    this.lineCurrent = this.drawDirectionLine(scene, 0xffff00); // Yellow for current direction
    this.lineTarget = this.drawDirectionLine(scene, 0x00ff00); // Green for target direction
  }

  // Helper to draw direction lines
  drawDirectionLine(scene: THREE.Scene, color: number): THREE.Line {
    const lineGeometry = new THREE.BufferGeometry();
    const points = [
      this.mesh.position, // Start at the center of the model
      new THREE.Vector3(this.mesh.position.x + this.moveDistance, 0, this.mesh.position.z), // Example point
    ];
    lineGeometry.setFromPoints(points);
    const lineMaterial = new THREE.LineBasicMaterial({ color: color });
    const line = new THREE.Line(lineGeometry, lineMaterial);
    scene.add(line);
    return line;
  }

  updateDirectionLine(scene: THREE.Scene, newAngle: number) {
    if (!this.lineTarget) return;

    // Calculate the new target direction
    const targetX = this.mesh.position.x + this.moveDistance * Math.cos(newAngle);
    const targetZ = this.mesh.position.z + this.moveDistance * Math.sin(newAngle);
    const newTarget = new THREE.Vector3(targetX, 0, targetZ);

    // Update the green line (target direction)
    const points = [this.mesh.position, newTarget];
    this.lineTarget.geometry.setFromPoints(points);
  }

  // Handle rotation based on key press (R key)
  handleRotationStart(scene: THREE.Scene) {
    this.isRotating = true;
    this.startAngle = this.mesh.rotation.y;
    this.drawRotationCircle(scene); // Draw the rotation circle and direction lines
  }

  handleRotationUpdate(scene: THREE.Scene, mouseAngle: number) {
    if (!this.isRotating) return;

    // Update target direction based on mouse angle
    this.updateDirectionLine(scene, mouseAngle);
  }

  handleRotationEnd(scene: THREE.Scene, finalAngle: number) {
    if (!this.isRotating) return;

    // Apply the final rotation to the model
    this.mesh.rotation.y = finalAngle;
    this.isRotating = false;

    // Remove rotation UI elements (circle and lines)
    if (this.rotateCircle) {
      scene.remove(this.rotateCircle);
    }
    if (this.lineCurrent) {
      scene.remove(this.lineCurrent);
    }
    if (this.lineTarget) {
      scene.remove(this.lineTarget);
    }
  }
}

export default Model;
