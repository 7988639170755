import * as THREE from 'three';

export function initializeGround(length: number, width: number): Promise<THREE.Mesh> {
  return new Promise((resolve, reject) => {
    const textureLoader = new THREE.TextureLoader();
    const groundTextureURL = 'https://www.crankwargame.com/cdn/shop/products/realm-of-nature-60x44-game-mat-wargame-age-of-sigmar-crank-wargame-1_1300x.jpg?v=1694899891';
    const groundGeometry = new THREE.PlaneGeometry(length, width);

    textureLoader.load(
      groundTextureURL,
      (texture) => {
        const groundMaterial = new THREE.MeshBasicMaterial({ map: texture, side: THREE.DoubleSide });
        const ground = new THREE.Mesh(groundGeometry, groundMaterial);
        ground.rotation.x = -Math.PI / 2; // Rotate to lie on the XY plane
        ground.position.set(0, 0, 0); // Ensure the ground is centered
        resolve(ground); // Resolve the promise with the ground mesh
      },
      undefined,
      (error) => {
        reject(error); // Reject the promise if there's an error loading the texture
      }
    );
  });
}
