import * as THREE from 'three';
import Terrain from './Terrain';

class TerrainManager {
  private terrains: Terrain[] = [];
  private scene: THREE.Scene;
  private selectedTerrain: Terrain | null = null;

  constructor(scene: THREE.Scene) {
    this.scene = scene;
  }

  getTerrains(): Terrain[] {
    return this.terrains;
  }

  addTerrain(terrain: Terrain) {
    this.terrains.push(terrain);
    this.scene.add(terrain.mesh); // Add the terrain's mesh to the scene
  }

  removeTerrain(terrain: Terrain) {
    const index = this.terrains.indexOf(terrain);
    if (index > -1) {
      this.terrains.splice(index, 1);
      this.scene.remove(terrain.mesh); // Remove the terrain's mesh from the scene
    }
  }

  handleMouseDown(event: MouseEvent, camera: THREE.Camera) {
    const mouse = new THREE.Vector2(
      (event.clientX / window.innerWidth) * 2 - 1,
      -(event.clientY / window.innerHeight) * 2 + 1
    );

    const raycaster = new THREE.Raycaster();
    raycaster.setFromCamera(mouse, camera);

    const intersects = raycaster.intersectObjects(this.terrains.map(t => t.mesh));

    if (intersects.length > 0) {
      this.selectedTerrain = this.terrains.find(t => t.mesh === intersects[0].object) || null;
      if (this.selectedTerrain) {
        this.selectedTerrain.startDragging();
      }
    }
  }

  handleMouseMove(event: MouseEvent, camera: THREE.Camera, ground: THREE.Mesh) {
    if (this.selectedTerrain && this.selectedTerrain.isDragging) {
      const mouse = new THREE.Vector2(
        (event.clientX / window.innerWidth) * 2 - 1,
        -(event.clientY / window.innerHeight) * 2 + 1
      );

      const raycaster = new THREE.Raycaster();
      raycaster.setFromCamera(mouse, camera);

      // Intersect with the ground plane to find the correct position
      const planeIntersect = raycaster.intersectObject(ground);

      if (planeIntersect.length > 0) {
        const point = planeIntersect[0].point;
        // Ensure the terrain stays above the ground
        const groundLevel = ground.position.y; // Assuming ground is at y = 0
        const terrainHeight = this.selectedTerrain.mesh.geometry.boundingBox?.max.y || 0;
        const minY = groundLevel + terrainHeight / 2;
        this.selectedTerrain.mesh.position.set(point.x, Math.max(minY, this.selectedTerrain.mesh.position.y), point.z);
        this.selectedTerrain.updateBoundingBox();
      }
    }
  }

  handleMouseUp() {
    if (this.selectedTerrain) {
      this.selectedTerrain.stopDragging();
      this.selectedTerrain = null;
    }
  }

  handleKeyDown(event: KeyboardEvent) {
    if (event.key === 'u' && this.selectedTerrain) {
      this.selectedTerrain.startYAdjusting();
    }
  }

  handleKeyUp(event: KeyboardEvent) {
    if (event.key === 'u' && this.selectedTerrain) {
      this.selectedTerrain.stopYAdjusting();
    }
  }

  dispose() {
    this.terrains.forEach((terrain) => {
      this.scene.remove(terrain.mesh);
    });
    this.terrains = [];
  }
}

export default TerrainManager;
