import React, { useEffect, useRef, useState } from 'react';
import * as THREE from 'three';
import Model from '../Objects/Model';
import ModelManager from '../Objects/ModelManager';
import HamburgerMenu from './HamburgerMenu';
import Sidebar from './Sidebar';
import { initializeGround } from '../Helpers/Ground';
import Terrain from '../Objects/Terrain';
import TerrainManager from '../Objects/TerrainManager';
const GROUND_HEIGHT = 0.1;

function createElongatedCubeTerrain(width: number, height: number, depth: number, material: THREE.Material): Terrain {
  // Create a box geometry with the specified dimensions
  const geometry = new THREE.BoxGeometry(width, height, depth);

  // Create a new Terrain object with the geometry and material
  const terrain = new Terrain(geometry, material);
  terrain.mesh.position.set(10, 1, 0); // Set the initial position
  terrain.updateBoundingBox();
  return terrain;
}

const ThreeScene: React.FC = () => {
  const mountRef = useRef<HTMLDivElement | null>(null);
  const [log, setLog] = useState<string>(''); // State to hold log messages
  const [logVisible, setLogVisible] = useState<boolean>(false); // State to manage log visibility
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false); // State for sidebar visibility
  const [, setDebugVisible] = useState<boolean>(false);
  const logRef = useRef<HTMLDivElement | null>(null);

  const logMessage = (message: string) => {
    setLog((prev) => `${prev}\n${message}`); // Append new log messages
  };

  useEffect(() => {
    if (logRef.current) {
      logRef.current.scrollTop = logRef.current.scrollHeight; // Scroll to the bottom
    }
  }, [log]);

  useEffect(() => {
    const mountNode = mountRef.current;
    if (!mountNode) return;

    // Set up the scene, camera, and renderer
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    const renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setPixelRatio(window.devicePixelRatio);
    mountNode.appendChild(renderer.domElement);

    // Define the initial position for the model
    const initialModelPosition = new THREE.Vector3(0, 0.5, 0);
    const model2Position = new THREE.Vector3(5,0.5,0);

    // Create a ModelManager and add a model to it
    const modelManager = new ModelManager(scene, logMessage);
    const model = new Model(scene, 5, initialModelPosition); // 5 inches move distance
    modelManager.addModel(model); // Add the model to the manager
    const model2 = new Model(scene, 7, model2Position);
    modelManager.addModel(model2);

    // Create a Terrain manager and add a terrain to it

    const terrainManager = new TerrainManager(scene);

    // Define the dimensions and material for the terrain
    const width = 5;  // Width of the cube
    const height = 2;  // Height of the cube (thin to simulate a ground plane)
    const depth = 5;  // Depth of the cube (elongated dimension)
    const material = new THREE.MeshBasicMaterial({ color: 0x00ff00 });

    // Create the terrain and add it to the manager
    const elongatedCubeTerrain = createElongatedCubeTerrain(width, height, depth, material);
    terrainManager.addTerrain(elongatedCubeTerrain);

    // Position the camera to look at the center of the ground plane at a 45-degree angle
    const angle = 70 * Math.PI / 180; // 45 degrees in radians
    const cameraPosition  = {
      x: -10 * Math.sin(angle),
      y: 50 * Math.cos(angle),
      z: 1 * Math.cos(angle)
    }

    // Calculate camera position based on angle and distance
    camera.position.set(cameraPosition.x, cameraPosition.y, cameraPosition.z);
    camera.lookAt(0, 0, 0); // look at the origin
    camera.rotation.y = 90 * Math.PI / 180 // rotate the camera 90 degrees

    let ground: THREE.Mesh;

    // Add a plane to represent the ground, limited to 40" by 60"
    const addGroundToScene = async () => {
      try {
        ground = await initializeGround(40, 60); // Wait for the ground to be initialized
        scene.add(ground); // Add the ground mesh to the scene
      } catch (error) {
        console.error('Error loading ground texture:', error);
      }
    };

    addGroundToScene();

    // grahical helpers
    const gridHelper = new THREE.GridHelper(100, 10); // Size and divisions
    const axesHelper = new THREE.AxesHelper(5); // Size of the axes
    let boxHelpers: THREE.BoxHelper[] = [];

    // Camera movement variables
    const cameraSpeed = 0.1;
    const zoomSpeed = 0.5; // Speed of zoom
    const rotationSpeed = 0.09; // Speed of rotation
    const keysPressed: { [key: string]: boolean } = {};

    // Variables for pitch and yaw
    let pitch = -Math.PI / 4; // Initial pitch for 45-degree angle
    let yaw = 0; // Initial yaw

    // Event listener for mouse clicks
    const onMouseDown = (event: MouseEvent) => {
      modelManager.handleMouseDown(event, camera);
      terrainManager.handleMouseDown(event, camera);
    };

    // Event listener for mouse movement
    const onMouseMove = (event: MouseEvent) => {
      modelManager.handleMouseMove(event, camera, ground, terrainManager.getTerrains());
      terrainManager.handleMouseMove(event, camera, ground);
    };

    // Event listener for mouse release
    const onMouseUp = () => {
      modelManager.handleMouseUp();
      terrainManager.handleMouseUp();
    };

    // Event listener for mouse wheel (zoom)
    const onWheel = (event: WheelEvent) => {
      event.preventDefault(); // Prevent the default scroll behavior
      const direction = new THREE.Vector3();
      camera.getWorldDirection(direction);
      camera.position.addScaledVector(direction, event.deltaY * zoomSpeed * -0.01);
    };

    // Event listeners for keyboard input
    const onKeyDown = (event: KeyboardEvent) => {
      keysPressed[event.key.toLowerCase()] = true;
      if (event.ctrlKey && event.key === 'l') {
        setLogVisible((prev) => !prev); // Toggle log visibility
      }
      if (event.ctrlKey && event.key === 'h') {
        setDebugVisible((prev) => {
          const newValue = !prev; // Calculate the new value

          // Add or remove helpers based on the new value
          if (newValue) {
            logMessage(`Add helpers`);
            scene.add(gridHelper);
            scene.add(axesHelper);
            const modelHelpers = modelManager.models.map((model) => {
              const boxHelper = new THREE.BoxHelper(model.mesh, 0xff0000);
              scene.add(boxHelper);
              return boxHelper
            });
            const terrainHelpers = terrainManager.getTerrains().map((terrain) => {
              const boxHelper = new THREE.BoxHelper(terrain.mesh, 0xff0000);
              scene.add(boxHelper);
              return boxHelper
            });
            boxHelpers = [...modelHelpers, ...terrainHelpers];
          } else {
            logMessage(`Remove helpers`);
            scene.remove(gridHelper);
            scene.remove(axesHelper);
            boxHelpers.forEach((helper) => {
              scene.remove(helper);
            });
            boxHelpers = [];
          }

          return newValue; // Return the new value
        });
      }
      if (event.key === 'r'|| event.key === 'u') {
        modelManager.handleKeyDown(event);
      }
    };

    const onKeyUp = (event: KeyboardEvent) => {
      keysPressed[event.key.toLowerCase()] = false;
      if (event.key === 'r' || event.key === 'u') {
        modelManager.handleKeyUp(event);
      }
    };

    // Add event listeners
    window.addEventListener('mousedown', onMouseDown);
    window.addEventListener('mousemove', onMouseMove);
    window.addEventListener('mouseup', onMouseUp);
    window.addEventListener('wheel', onWheel, { passive: false }); // Set passive to false to allow preventDefault
    window.addEventListener('contextmenu', (e) => e.preventDefault()); // Prevent context menu on right click
    window.addEventListener('keydown', onKeyDown);
    window.addEventListener('keyup', onKeyUp);

    // Animation loop
    const animate = () => {
      requestAnimationFrame(animate);

      modelManager.updateModels(terrainManager.getTerrains()); // Update all models in the manager

      // Camera fly movement logic
      const direction = new THREE.Vector3();
      camera.getWorldDirection(direction);

      const originalPosition = camera.position.clone();

      direction.y = 0; // Keep the movement horizontal
      direction.normalize();

      // Movement controls
      if (keysPressed['w']) {
        camera.position.addScaledVector(direction, cameraSpeed);
      }
      if (keysPressed['s']) {
        camera.position.addScaledVector(direction, -cameraSpeed);
      }
      if (keysPressed['a']) {
        const left = new THREE.Vector3().crossVectors(camera.up, direction).normalize();
        camera.position.addScaledVector(left, cameraSpeed);
      }
      if (keysPressed['d']) {
        const right = new THREE.Vector3().crossVectors(camera.up, direction).normalize();
        camera.position.addScaledVector(right, -cameraSpeed);
      }
      // Spacebar for upward movement
      if (keysPressed[' ']) {
        camera.position.y += 0.1; // Adjust the value for speed of upward movement
      }
      if (keysPressed['c']) {
        camera.position.y -= 0.1; // Adjust the value for speed of downward movement
      }

      // Collision detection with the ground
      if (camera.position.y < GROUND_HEIGHT) {
        camera.position.y = GROUND_HEIGHT; // Prevent camera from going below ground
      }

      // Check for collision with the ground when moving down
      if (originalPosition.y > GROUND_HEIGHT && camera.position.y < GROUND_HEIGHT) {
        camera.position.y = GROUND_HEIGHT; // Prevent camera from going below ground
      }

      // Camera rotation logic with arrow keys
      if (keysPressed['arrowup']) {
        pitch -= rotationSpeed * 0.1;
      }
      if (keysPressed['arrowdown']) {
        pitch += rotationSpeed * 0.1;
      }
      if (keysPressed['arrowleft'] || keysPressed['q']) {
        yaw -= rotationSpeed * 0.1;
      }
      if (keysPressed['arrowright'] || keysPressed['e']) {
        yaw += rotationSpeed * 0.1;
      }

      // Clamp pitch to prevent flipping
      pitch = Math.max(-Math.PI / 2, Math.min(Math.PI / 2, pitch));

      // Update camera direction based on pitch and yaw
      const newDirection = new THREE.Vector3(
        Math.cos(pitch) * Math.cos(yaw),
        Math.sin(pitch),
        Math.cos(pitch) * Math.sin(yaw)
      );

      // Calculate the target point based on the camera's position and direction
      const target = camera.position.clone().add(newDirection);
      camera.lookAt(target); // Look at the calculated target point

      // update helpers
      boxHelpers.forEach((helper, index) => {
        if (index < modelManager.models.length) {
          helper.update(); // Update the helper to match the model's position
        } else {
          const terrainIndex = index - modelManager.models.length;
          if (terrainIndex < terrainManager.getTerrains().length) {
            helper.update(); // Update the helper to match the terrain's position
          }
        }
      });

      renderer.render(scene, camera);
    };
    animate();

    // Cleanup on component unmount
    return () => {
      window.removeEventListener('mousedown', onMouseDown);
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('mouseup', onMouseUp);
      window.removeEventListener('wheel', onWheel);
      window.removeEventListener('keydown', onKeyDown);
      window.removeEventListener('keyup', onKeyUp);

      // Dispose of the scene, geometry, and renderer to prevent memory leaks
      scene.traverse((object) => {
        if (object instanceof THREE.Mesh) {
          object.geometry.dispose();
          if (Array.isArray(object.material)) {
            object.material.forEach((mat) => mat.dispose());
          } else {
            object.material.dispose();
          }
        }
      });

      renderer.dispose();

      if (mountNode) {
        mountNode.removeChild(renderer.domElement);
      }
    };
  }, []);

  return (
    <div ref={mountRef} className="relative">
      <HamburgerMenu onToggle={() => setSidebarOpen((prev) => !prev)} />
      <Sidebar isOpen={sidebarOpen} onClose={() => setSidebarOpen(false)} />
      {logVisible && (
        <div
          ref={logRef}
          className="absolute top-10 left-10 bg-white/50 border border-black p-4 max-w-s max-h-72 overflow-y-auto z-50 text-sm text-black"
        >
          <pre>{log}</pre>
        </div>
      )}
    </div>
  );
};

export default ThreeScene;
